import React from 'react'
import { Text, LanguageContext } from "../containers/Language";
const moblielotimage = require("../images/Online_Training_Videos.png");
export default function TransactionRemovedUnsold() {
  return (
    <div>
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img src={moblielotimage} className="mobilelot-image" />
    </div>
    <div
      /* style={{
        background: "#DCDCDC",
        display: "flex",
        alignItems: "center",
        padding: "30px",
        width: "100%",
        marginLeft: "0%",
        marginTop: "0.3px",
      }} */
      className="video_content_header"
    >
      <div>
        <h5 style={{ color: "#00035A" }}>
          {" "}
          <Text tid="transaction_remove_unsold" />
        </h5>
      </div>
      <div style={{ marginLeft: "50%" }}>
        <div>
          <h5 style={{ color: "#00035A" }}>
            <Text tid="language" /> <Text tid="colon" />{" "}
            <Text tid="english" />
          </h5>
        </div>
        <div>
          <h5 style={{ color: "#00035A" }}>
            <Text tid="duration" /> <Text tid="colon" />{" "}
            <Text tid="remove_unsold_duration" />
          </h5>
        </div>
      </div>
    </div>
    <div
      /* style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        marginTop: "0px",
      }} */
      className="video_content_frame"
    >
      <iframe
        /* width="1140"
        height="642" */
        className="iframe_content"
        src="https://www.youtube.com/embed/P4n_Ik4xRKM"
        title="Transaction Remove Unsold"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    </div>
  </div>
  )
}
