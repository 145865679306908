import { FaBars } from "react-icons/fa";
import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";

export const Nav = styled.nav`
  background: #C50101;
  height: 45px;
  display: flex;
  justify-content: space-between;
  z-index: 12;
  padding: 0.2rem calc((100vw - 1000px) / 2);
  @media screen and (max-width: 768px) {
  height: 270px;

  }
   `;

  export const NavHeader = styled.nav`
  background: #FFFFFF;
  height: 50px;
  display: flex;
  justify-content: space-between;
  z-index: 12;
  padding: 0.2rem calc((100vw - 1000px) / 2); 
  `;

export const NavLink = styled(Link)`
  color: #FFFFFF;
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 100%;
  padding: 0 1rem; 
  `;

export const Bars = styled(FaBars)`
  display: none;
  color: #808080;
  width:100%
  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    
  }
`;

export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: -24px;
  width: 100vw;
  @media screen and (max-width: 768px){
    flex-direction: column;
    display: flex;
    text-align: left;
    align-items: left;
  }
`;

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;
  margin-right: 24px;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavBtnLink = styled(Link)`
  border-radius: 4px;
  background: #808080;
  padding: 10px 22px;
  color: #000000;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  /* Second Nav */
  margin-left: 24px;
  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #808080;
  }
`;
/* Specific styles for non-active links */
export const background_Color =styled(Link)`
color: white;
`;
/* Specific styles for active links */
export const acttive_background= styled(Link)`
color: black;
  background: orange;
`



